<app-todo
    *ngIf="move"
    [label]="'DETAIL.ENERGY_STOP.METER_READINGS_COMPLETED' | i18nKeyTranslate: i18nKeys.AdminDashboard"
    [checked]="energyStop?.states.meterComplete"
>
    <app-todo-template *todoStateChecked>
        <ng-container todo-cta *ngTemplateOutlet="openMeterInfoCta"></ng-container>
    </app-todo-template>

    <app-todo-template *todoStateUnchecked [label]="'DETAIL.METER_READINGS.NOK' | i18nKeyTranslate: i18nKeys.RealEstateAgentDashboard">
        <a app-button [context]="uiContext.Secondary" [size]="buttonSize.Sm" (click)="openMeterInfo()"> {{ 'ADD' | translate }} </a>
    </app-todo-template>
</app-todo>

<ng-template #openMeterInfoCta>
    <a app-button [appearance]="buttonAppearance.Link" [size]="buttonSize.Sm" (click)="openMeterInfo()">{{ 'VIEW' | translate }}</a>
</ng-template>
