<app-move-detail-extra-info *ngIf="energyStop" [collapsed]="!energyStop.active">
    <ng-container content>
        <app-info-block>
            <app-info-detail [label]="'ENERGY_TYPE.LABEL' | translate">
                <app-copy-to-clipboard>
                    {{ 'ENERGY_TYPE.' + energyStop.energyStopActiveServices?.toUpperCase() | translate }}
                </app-copy-to-clipboard>
            </app-info-detail>
        </app-info-block>

        <app-info-block [label]="'ELECTRICITY' | translate">
            <p class="u-font-weight-semi-bold u-margin-bottom-half">
                {{ 'DETAIL.ENERGY_STOP.TRANSFER_NEEDED.ELECTRICITY' | i18nKeyTranslate: i18nKeys.AdminDashboard }}
                @if (hasElectricity) {
                    <span class="u-color-success">{{ 'YES' | translate }}</span>
                } @else {
                    <span class="u-color-error">{{ 'NO' | translate }}</span>
                }
            </p>
            <span [class.u-opacity-50]="!hasElectricity">
                <app-info-detail [label]="'DETAIL.ENERGY_TRANSFER.CURRENT_SUPPLIER.LABEL' | i18nKeyTranslate: i18nKeys.AdminDashboard">
                    <app-copy-to-clipboard>
                        {{ energyStop?.supplierElectricity }}
                    </app-copy-to-clipboard>
                </app-info-detail>

                <app-info-detail [label]="'METER_TYPE' | translate">
                    <app-copy-to-clipboard *ngIf="electricityMeter.meterType">{{
                        'ENERGY.METER_TYPE.' + electricityMeter?.meterType?.toUpperCase() | translate
                    }}</app-copy-to-clipboard>
                </app-info-detail>

                <app-info-detail [label]="'MOVE.EAN_NUMBER' | translate">
                    <app-copy-to-clipboard>{{ electricityMeter.eanCode }}</app-copy-to-clipboard>
                </app-info-detail>

                <app-info-detail [label]="'MOVE.METER_NUMBER' | translate">
                    <app-copy-to-clipboard>{{ electricityMeter.meterNumber }}</app-copy-to-clipboard>
                </app-info-detail>

                <app-info-detail [label]="'ENERGY.METER_READING.VALIDATED' | translate">
                    @if (electricityMeter.validated) {
                        <span>{{ 'YES' | translate }}</span>
                    } @else {
                        <span>{{ 'NO' | translate }}</span>
                    }
                </app-info-detail>

                <ng-container [ngSwitch]="electricityMeter.meterType">
                    <ng-container *ngSwitchCase="meterTypes.Double">
                        <app-info-detail [label]="'MOVE.ENERGY.READING.DAY' | translate">
                            <app-copy-to-clipboard>{{ electricityMeter.readings?.meterReading }}</app-copy-to-clipboard>
                        </app-info-detail>

                        <app-info-detail [label]="'MOVE.ENERGY.READING.NIGHT' | translate">
                            <app-copy-to-clipboard>{{ electricityMeter.readings?.meterReadingNight }}</app-copy-to-clipboard>
                        </app-info-detail>
                    </ng-container>

                    <ng-container *ngSwitchCase="meterTypes.Digital">
                        <app-info-detail
                            *ngIf="linkedMove.energyDigitalMeterReadings?.automatic; else digitalMeterReadings"
                            [label]="'ENERGY.METER_READING.DIGITAL.AUTOMATIC_READING' | translate"
                        >
                            <app-copy-to-clipboard>{{ linkedMove.movingDate | formatDate }}</app-copy-to-clipboard>
                        </app-info-detail>
                        <ng-template #digitalMeterReadings>
                            <app-info-detail [label]="'MOVE.ENERGY.CONSUMPTION.DAY' | translate">
                                <app-copy-to-clipboard>{{ electricityMeter.readings?.meterReading }}</app-copy-to-clipboard>
                            </app-info-detail>
                            <app-info-detail [label]="'MOVE.ENERGY.CONSUMPTION.NIGHT' | translate">
                                <app-copy-to-clipboard>{{ electricityMeter.readings?.meterReadingNight }}</app-copy-to-clipboard>
                            </app-info-detail>
                            <app-info-detail [label]="'ENERGY.HAS_INJECTION' | translate">
                                @if (electricityMeter.hasInjection) {
                                    <span class="u-color-success">{{ 'YES' | translate }}</span>
                                } @else {
                                    <span class="u-color-error">{{ 'NO' | translate }}</span>
                                }
                            </app-info-detail>
                            <app-info-detail [label]="'ENERGY.INJECTION.DAY' | translate">
                                <app-copy-to-clipboard>{{ electricityMeter.readings?.meterReadingInjectionDay }}</app-copy-to-clipboard>
                            </app-info-detail>
                            <app-info-detail [label]="'ENERGY.INJECTION.NIGHT' | translate">
                                <app-copy-to-clipboard>{{ electricityMeter.readings?.meterReadingInjectionNight }}</app-copy-to-clipboard>
                            </app-info-detail>
                        </ng-template>
                    </ng-container>

                    <app-info-detail *ngSwitchDefault [label]="'MOVE.ENERGY.READING' | translate">
                        <app-copy-to-clipboard>{{ electricityMeter.readings?.meterReading }}</app-copy-to-clipboard>
                    </app-info-detail>
                </ng-container>

                <app-info-detail [label]="'EXCLUSIVE_NIGHT' | translate">
                    @if (electricityMeter.exclusiveNight) {
                        <span class="u-color-success">{{ 'YES' | translate }}</span>
                    } @else {
                        <span class="u-color-error">{{ 'NO' | translate }}</span>
                    }
                </app-info-detail>

                <app-info-detail
                    *ngIf="!!electricityMeter.readings?.meterReadingExclusiveNight"
                    [label]="'MOVE.ENERGY.CONSUMPTION.EXCLUSIVE_NIGHT' | translate"
                >
                    <app-copy-to-clipboard>{{ electricityMeter.readings?.meterReadingExclusiveNight }}</app-copy-to-clipboard>
                </app-info-detail>
            </span>
        </app-info-block>

        <app-info-block [label]="'GAS' | translate">
            <p class="u-font-weight-semi-bold u-margin-bottom-half">
                {{ 'DETAIL.ENERGY_STOP.TRANSFER_NEEDED.GAS' | i18nKeyTranslate: i18nKeys.AdminDashboard }}
                <span *ngIf="hasGas" class="u-color-success">{{ 'YES' | translate }}</span
                ><span *ngIf="!hasGas" class="u-color-error">{{ 'NO' | translate }}</span>
            </p>
            <span [class.u-opacity-50]="!hasGas">
                <app-info-detail [label]="'DETAIL.ENERGY_TRANSFER.CURRENT_SUPPLIER.LABEL' | i18nKeyTranslate: i18nKeys.AdminDashboard">
                    <app-copy-to-clipboard>{{ energyStop?.supplierGas }}</app-copy-to-clipboard>
                </app-info-detail>

                <app-info-detail [label]="'MOVE.EAN_NUMBER' | translate">
                    <app-copy-to-clipboard>{{ gasMeter.eanCode }}</app-copy-to-clipboard>
                </app-info-detail>

                <app-info-detail [label]="'MOVE.METER_NUMBER' | translate">
                    <app-copy-to-clipboard>{{ gasMeter.meterNumber }}</app-copy-to-clipboard>
                </app-info-detail>

                <app-info-detail [label]="'ENERGY.METER_READING.VALIDATED' | translate">
                    @if (gasMeter.validated) {
                        <span>{{ 'YES' | translate }}</span>
                    } @else {
                        <span>{{ 'NO' | translate }}</span>
                    }
                </app-info-detail>

                <app-info-detail [label]="'METER_READING' | translate">
                    <app-copy-to-clipboard>{{ gasMeter.meterReading }}</app-copy-to-clipboard>
                </app-info-detail>
            </span>
        </app-info-block>

        <app-info-block
            [label]="'DOCUMENTS' | translate"
            *ngIf="linkedMove.energyMeterReadingAssets?.length || linkedMove.energyDocumentAssets?.length"
        >
            <app-asset
                *ngFor="let file of linkedMove.energyMeterReadingAssets"
                [label]="'METER_READINGS_ENERGY' | translate"
                [file]="file"
                (click)="openMeterReadingAsset(file)"
                role="button"
            ></app-asset>
            <app-asset
                *ngFor="let file of linkedMove.energyDocumentAssets"
                [label]="'AGREEMENT.ENERGY' | translate"
                [file]="file"
                (click)="openEnergyDocumentAsset(file)"
                role="button"
            ></app-asset>
        </app-info-block>
    </ng-container>
</app-move-detail-extra-info>
